<template>
  <div>
    <!-- {{ unit }} -->
    <v-form ref="composeUnitForm">
      <v-card rounded="0" elevation="0">
        <v-card-title class="text-uppercase font-weight-light grey lighten-5">
          <v-icon large class="mr-2">mdi-view-week-outline</v-icon>{{ tLabel("Unit") }}
          <v-spacer />
          <v-btn depressed :outlined="page !== 'INFO'" color="primary" class="mr-2 rounded-0" @click="page = 'INFO'">
            <v-icon class="mr-2">mdi-information-outline</v-icon>{{ tLabel("Info") }}
          </v-btn>
          <v-btn depressed :outlined="page !== 'ITINERARY'" color="primary" class="mr-2 rounded-0" @click="page = 'ITINERARY'">
            <v-icon class="mr-2">mdi-map-marker-path</v-icon>{{ tLabel("Departure") + " / " + tLabel("Destination") }}
          </v-btn>
          <v-btn depressed :outlined="page !== 'DAMAGES'" color="primary" class="mr-2 rounded-0" @click="page = 'DAMAGES'">
            <v-icon class="mr-2">mdi-image-broken-variant</v-icon>{{ tLabel("Damages") }}
          </v-btn>
          <v-btn depressed :outlined="page !== 'EXIT'" color="primary" class="rounded-0" @click="page = 'EXIT'">
            <v-icon class="mr-2">mdi-train</v-icon>{{ tLabel("Exit") }}
          </v-btn>
        </v-card-title>
        <v-divider class="warning"></v-divider>
        <v-card-text v-show="page === 'INFO'">
          <v-row align="center">
            <v-col cols="2">
              <v-text-field
                dense
                :label="tLabelRequired('Unit Code')"
                clearable
                v-model="unit.code"
                @change="onChangeUnitCode"
                @input="(val) => (unit.code = val ? val.toUpperCase() : null)"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="1">
              <v-checkbox dense label="Forza UNIT" v-model="unit.skipCheckDigitUtiId" />
            </v-col>
            <v-col cols="2">
              <v-select dense :label="tLabelRequired('Unit Class')" v-model="unit.typeClass" :items="unitSysSubtypes" @change="onChangeSysSubtype" :rules="[rules.required]" />
            </v-col>
            <v-col cols="2">
              <!-- {{ unitTypes }} -->
              <v-autocomplete
                dense
                clearable
                :label="tLabelRequired('Unit Type')"
                v-model="unitTypeSelected"
                :items="unitTypes"
                item-text="unitTypeCode"
                item-value="value"
                return-object
                :disabled="unit.typeClass == null"
                @change="onChangeUnitType"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field dense :label="tLabel('Goods')" v-model="unit.goods" />
            </v-col>
          </v-row>
          <v-row align="center" class="grey lighten-3">
            <v-col cols="3">
              <v-text-field dense :background-color="editUnitAttrs ? 'white' : null" :label="tLabel('ISO size type')" v-model="unit.isoSizeType" :readonly="!editUnitAttrs" />
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                :background-color="editUnitAttrs ? 'white' : null"
                :label="tLabel('Tare Mass')"
                v-model.number="unit.tareMass"
                :rules="[rules.numeric]"
                :readonly="!editUnitAttrs"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                :background-color="editUnitAttrs ? 'white' : null"
                :label="tLabel('Length')"
                v-model.number="unit.length"
                :rules="[rules.numeric]"
                :readonly="!editUnitAttrs"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                :background-color="editUnitAttrs ? 'white' : null"
                :label="tLabel('Width')"
                v-model.number="unit.width"
                :rules="[rules.numeric]"
                :readonly="!editUnitAttrs"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                :background-color="editUnitAttrs ? 'white' : null"
                :label="tLabel('Height')"
                v-model.number="unit.height"
                :rules="[rules.numeric]"
                :readonly="!editUnitAttrs"
              />
            </v-col>
            <v-col cols="1" class="text-center" v-if="!editUnitAttrs">
              <v-row align="center" class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0 text-center">
                  <v-btn outlined small @click="refreshEditUnitAttrs" :title="tLabel('Refresh')">
                    <v-icon size="20">mdi-refresh</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 text-center">
                  <v-btn small color="primary" @click="prepareEditUnitAttrs" :title="tLabel('Edit attributes unit type')">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" class="text-center" v-else>
              <v-btn outlined small @click="discardEditUnitAttrs" :title="tLabel('Annulla')">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
              <v-btn small color="primary" class="ml-2" @click="editUnitAttrs = false" :title="tLabel('Salva')">
                <v-icon small>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3">
              <v-text-field dense :label="tLabel('Colli')" v-model.number="unit.quantity" :rules="[rules.numeric]" />
            </v-col>
            <v-col cols="3">
              <v-text-field dense :label="tLabel('Gross weight')" v-model.number="unit.grossweight" :rules="[rules.numeric]" />
            </v-col>
            <v-col cols="3">
              <v-text-field dense :label="tLabel('Net weight')" v-model.number="unit.netWeight" :rules="[rules.numeric]" />
            </v-col>
            <v-col cols="3" class="text-right">
              <v-checkbox dense :label="tLabel('Dangerous')" v-model="unit.dangerous" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3">
              <v-select
                dense
                clearable
                :label="fullEmptyRuleEnabled ? tLabelRequired('Full/Empty') : tLabel('Full/Empty')"
                v-model="unit.fullEmpty"
                :items="fullEmpties"
                :rules="fullEmptyRuleEnabled ? [rules.required] : []"
                @change="onChangeFE"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field dense :label="tLabel('Seal 1')" v-model="unit.seal1" @input="(val) => (unit.seal1 = val ? val.toUpperCase() : null)" />
            </v-col>
            <v-col cols="3">
              <v-text-field dense :label="tLabel('Seal 2')" v-model="unit.seal2" @input="(val) => (unit.seal2 = val ? val.toUpperCase() : null)" />
            </v-col>
            <v-col cols="3">
              <v-text-field dense :label="tLabel('Seal 3')" v-model="unit.seal3" @input="(val) => (unit.seal3 = val ? val.toUpperCase() : null)" />
            </v-col>
          </v-row>
          <v-divider class="my-6" />
          <v-row align="center">
            <v-col cols="3">
              <v-combobox
                dense
                :label="tLabel('Order')"
                v-model="unit.orderCode"
                :items="orders"
                item-value="reference"
                item-text="reference"
                @change="onChangeOrderCode"
                :filter="filterOrders"
                return-object
              >
                <template v-slot:item="{ item }">
                  <v-row>
                    <v-col cols="8" class="font-weight-bold blue--text">
                      {{ item.reference }} <span class="text-subtitle-1 font-weight-light pl-4">{{ item.extCode }}</span>
                    </v-col>
                    <v-col>
                      {{ item.type }}
                    </v-col>
                  </v-row>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="2">
              <v-select dense v-model="unit.cycle" :items="cyclesList" :label="tLabel('Cycle')" />
            </v-col>
            <v-col cols="3">
              <v-autocomplete dense :label="tLabel('Owner')" v-model="unit.ownerCompanyId" :items="owners" item-text="code" item-value="id" />
            </v-col>
            <v-col cols="2">
              <v-select dense :label="tLabelRequired('Customs Status')" v-model="unit.customsStatus" :items="customsStatus" :rules="[rules.required]" />
            </v-col>
            <v-col cols="3">
              <v-text-field dense :label="tLabel('Lot')" v-model="unit.lot" />
            </v-col>
          </v-row>
          <v-row align="end">
            <v-col cols="4">
              <v-text-field dense :label="tLabel('Position')" v-model="unit.positionCode" @input="(val) => (unit.positionCode = val ? val.toUpperCase() : null)" />
            </v-col>
            <v-col cols="8">
              <v-textarea dense :label="tLabel('Remarks')" v-model="unit.remarks" counter maxlength="255" rows="3" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-show="page === 'ITINERARY'">
          <ItineraryForm dense :itineraryVisit="unit.itineraryVisit" v-if="unit.itineraryVisit" />
        </v-card-text>
        <v-card-text v-show="page === 'DAMAGES'">
          <v-row>
            <v-col cols="5">
              <v-autocomplete dense :label="tLabel('Damage Type')" :items="damageTypes" clearable v-model="damageToAdd.damageType" :item-text="damageTypeRender" return-object />
            </v-col>
            <v-col cols="5">
              <v-text-field dense :label="tLabel('Position')" v-model="damageToAdd.position" @input="(val) => (damageToAdd.position = val ? val.toUpperCase() : null)" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="10">
              <v-textarea dense :label="tLabel('Remarks')" v-model="damageToAdd.remarks" counter maxlength="255" rows="3" />
            </v-col>
            <v-col cols="2" class="text-center">
              <v-btn color="primary" :disabled="!damageToAdd.damageType || damageToAdd.damageType === null" @click="addDamage"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <v-simple-table :key="damageKey" dense>
                <template v-slot:default>
                  <thead class="grey lighten-2">
                    <tr>
                      <th />
                      <th class="text-left">{{ tLabel("Type") }}</th>
                      <th class="text-left">{{ tLabel("Position") }}</th>
                      <th class="text-left">{{ tLabel("Remarks") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="unit.damages && unit.damages.length > 0">
                    <tr v-for="damage in unit.damages" :key="damage.damageType.id + damage.position">
                      <td>
                        <v-btn icon @click="removeDamage(damage)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                      <td>{{ damage.damageType.code }} - {{ damage.damageType.description }}</td>
                      <td>{{ damage.position }}</td>
                      <td>{{ damage.remarks }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4">No damages.</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-show="page === 'EXIT'">
          <v-row align="center">
            <v-col cols="3">
              <v-checkbox v-model="unit.doHandlingOut" class="text-uppercase" :label="tLabel('Uscita treno')" />
            </v-col>
            <v-col cols="3">
              <DateTimeAdapter :label="tLabel('Planned Date / Time')" v-model="unit.handlingOutPlannedDate" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6">
              <v-autocomplete
                dense
                :label="tLabel('Voyage code')"
                :items="listTrainVoyages"
                clearable
                v-model="unit.handlingOutTrainVoyage"
                @change="trainVoyageChanged"
                :item-text="voyageCodeRender"
                return-object
                v-if="listTrainVoyages && listTrainVoyages !== null && listTrainVoyages.length > 0"
              />
              <v-text-field dense readonly :label="tLabel('Voyage code')" value="No voyages." v-else />
            </v-col>
            <v-col cols="6">
              <v-autocomplete dense :label="tLabel('Targa Carro')" :items="listWagonLicensePlates" clearable v-model="unit.handlingOutWagon" item-text="code" return-object />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <v-textarea dense :label="tLabel('Remarks')" v-model="unit.handlingOutRemarks" counter maxlength="255" rows="3" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-dialog v-model="confirmAdvicedUnitsDialogVisible" max-width="50%">
      <v-card v-if="advicedUnit !== null">
        <v-card-title
          v-if="advicedUnit.visitStatus === 'E' || advicedUnit.visitStatus === 'Y' || advicedUnit.visitStatus === 'L'"
          class="text-h6 text-uppercase font-weight-light panelheader"
        >
          <v-icon color="warning" class="mr-2">mdi-alert</v-icon>{{ tLabel("Attenzione") }}
        </v-card-title>
        <v-card-title v-else class="text-h6 text-uppercase font-weight-light panelheader">
          {{ tLabel("Confirm") }}
        </v-card-title>
        <v-card-text class="text-h5">
          <v-row align="center">
            <v-col cols="12" class="my-2" v-if="advicedUnit.visitStatus === 'E' || advicedUnit.visitStatus === 'Y' || advicedUnit.visitStatus === 'L'">
              Unit <b>{{ advicedUnit.code }}</b> già presente in yard, entrata con <b>{{ advicedUnit.voyageCode }}</b
              >. <br />
              Non é quindi possibile aggiungerla alla consegna.
            </v-col>
            <v-col cols="12" class="my-2" v-else>
              Presente preavviso per unit <b>{{ advicedUnit.code }}</b
              >, in ingresso con <b>{{ advicedUnit.voyageCode }}</b
              >. <br />
              Usare i dati del preavviso?
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="advicedUnit.visitStatus === 'E' || advicedUnit.visitStatus === 'Y' || advicedUnit.visitStatus === 'L'"
            outlined
            @click="
              unit.code = null;
              confirmAdvicedUnitsDialogVisible = false;
            "
            ><v-icon>mdi-close</v-icon>{{ tLabel("Close") }}</v-btn
          >
          <v-btn
            v-else-if="advicedUnit.visitStatus === 'A'"
            outlined
            @click="
              unit.code = null;
              confirmAdvicedUnitsDialogVisible = false;
            "
            >{{ tLabel("No") }}</v-btn
          >
          <v-btn
            v-if="advicedUnit.visitStatus === 'A'"
            @click="
              preadviceToUnit();
              confirmAdvicedUnitsDialogVisible = false;
            "
            color="primary"
            >{{ tLabel("Si") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormatsMixins from "../../mixins/FormatsMixins";
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";
import ItineraryForm from "@/components/yard/ItineraryForm";
import moment from "moment";

export default {
  name: "UnitComposeForm",
  data() {
    return {
      owners: [],
      unitTypes: [],
      unitTypesAll: [],
      unitTypeSelected: null,
      editUnitAttrs: false,
      fullEmptyRuleEnabled: false,
      page: "INFO",
      damageTypes: [],
      damageToAdd: {},
      damageKey: 0,
      listTrainVoyages: [],
      listWagonLicensePlates: [],
      orders: [],
      defaultCyclesForOrderType: {},
      advicedUnit: [],
      confirmAdvicedUnitsDialogVisible: false,
      doHandlingOut: !!process.env.VUE_APP_PREADVICE_HANDLING_OUT,
    };
  },
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  mixins: [FormatsMixins, YardEnumMixin],
  components: { DateTimeAdapter, ItineraryForm },
  async mounted() {
    this.unit.itineraryVisit = {};
    this.unit.doHandlingOut = this.doHandlingOut;
    this.owners = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
    this.unitTypesAll = (await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "unit-types/list")).data;
    this.damageTypes = await this.$api.yardData.damageTypes();
    this.listTrainVoyages = await this.$api.yardData.listTrainVoyages();
    this.orders = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "orders/all");
    const parametersList = await this.$api.registryData.getParameters();
    const parametersMap = Object.assign({}, ...parametersList.map((s) => ({ [s.key]: s.value })));
    this.defaultCyclesForOrderType = {
      STUFFING: parametersMap["YARD.UNIT.STUFFING.CYCLE"] || "E",
      STRIPPING: parametersMap["YARD.UNIT.STRIPPING.CYCLE"] || "I",
    };
  },
  computed: {},
  methods: {
    validate() {
      return this.$refs.composeUnitForm.validate();
    },
    initForm() {
      this.$refs.composeUnitForm.resetValidation();
      this.$refs.composeUnitForm.reset();
      Object.keys(this.unit).forEach((attr) => {
        delete this.unit[attr];
      });
      this.unit.itineraryVisit = {};
      this.unit.doHandlingOut = this.doHandlingOut;
    },
    setUnitTypeSelected(unitTypeCode) {
      let ut = this.unitTypes.filter((ut) => ut.unitTypeCode === unitTypeCode);
      if (ut && ut !== null && ut.length > 0) {
        this.unitTypeSelected = JSON.parse(JSON.stringify(ut[0]));
      }
    },
    async onChangeUnitCode() {
      const unitCode = this.unit.code;
      if (unitCode && unitCode !== null && unitCode.length > 0) {
        this.advicedUnit = await this.$api.yardData.getPreadvicedUnit(unitCode);
        console.log("onChangeUnitCode advicedUnit", unitCode, JSON.stringify(this.advicedUnit), this.advicedUnit.visitStatus);
        if (this.advicedUnit && this.advicedUnit !== null) {
          if (this.advicedUnit.visitStatus === "D" || this.advicedUnit.visitStatus === "C") {
            this.preadviceToUnit();
          } else {
            this.confirmAdvicedUnitsDialogVisible = true;
          }
        }
      }
    },
    preadviceToUnit() {
      Object.assign(this.unit, this.advicedUnit);
      this.onChangeSysSubtype(this.advicedUnit.typeClass, true);
      this.setUnitTypeSelected(this.advicedUnit.typeCode);
      if (this.unitTypeSelected && this.unitTypeSelected !== null) {
        this.unit.unitTypeId = this.unitTypeSelected.id;
        this.unit.typeCode = this.unitTypeSelected.unitTypeCode;
      }
    },
    onChangeSysSubtype(unitType, notClean) {
      if (this.unit.typeCode) {
        delete this.unit["typeCode"];
      }
      this.unitTypeSelected = null;
      if (!notClean) {
        this.cleanUnitTypeFields();
      }
      this.unitTypes = this.unitTypesAll.filter((ut) => ut.sysSubtype == unitType);
      if (unitType === "CTR" || unitType === "RTL") {
        this.fullEmptyRuleEnabled = true;
      } else {
        this.fullEmptyRuleEnabled = false;
      }
    },
    cleanUnitTypeFields() {
      if (this.unit.isoSizeType) {
        delete this.unit["isoSizeType"];
      }
      if (this.unit.height) {
        delete this.unit["height"];
      }
      if (this.unit.width) {
        delete this.unit["width"];
      }
      if (this.unit.length) {
        delete this.unit["length"];
      }
      if (this.unit.tareMass) {
        delete this.unit["tareMass"];
      }
    },
    onChangeUnitType(unitType) {
      if (unitType.id) {
        this.unit.unitTypeId = unitType.id;
        this.unit.typeCode = unitType.unitTypeCode;
        this.unit.isoSizeType = unitType.isoSizeType;
        this.unit.height = unitType.height;
        this.unit.width = unitType.width;
        this.unit.length = unitType.length;
        this.unit.tareMass = unitType.tareMass;
      }
    },
    onChangeOrderCode() {
      const order = this.unit.orderCode;
      if (typeof order == "object") {
        this.unit.orderCode = order.reference;
        const defCycle = this.defaultCyclesForOrderType[order.type];
        if (defCycle) this.unit.cycle = defCycle;
        this.unit.ownerCompanyId = order.companyId;
      }
    },
    prepareEditUnitAttrs() {
      this.editUnitAttrs = true;
      this.unitAttrsPrev = {
        isoSizeType: this.unit.isoSizeType,
        tareMass: this.unit.tareMass,
        length: this.unit.length,
        width: this.unit.width,
        height: this.unit.height,
      };
    },
    discardEditUnitAttrs() {
      this.editUnitAttrs = false;
      this.unit.isoSizeType = this.unitAttrsPrev.isoSizeType;
      this.unit.tareMass = this.unitAttrsPrev.tareMass;
      this.unit.length = this.unitAttrsPrev.length;
      this.unit.width = this.unitAttrsPrev.width;
      this.unit.height = this.unitAttrsPrev.height;
    },
    refreshEditUnitAttrs() {
      if (this.unitTypeSelected && this.unitTypeSelected !== null) {
        this.unit.isoSizeType = this.unitTypeSelected.isoSizeType;
        this.unit.height = this.unitTypeSelected.height;
        this.unit.width = this.unitTypeSelected.width;
        this.unit.length = this.unitTypeSelected.length;
        this.unit.tareMass = this.unitTypeSelected.tareMass;
      }
    },
    addDamage() {
      if (!this.unit.damages) {
        this.unit.damages = new Array();
      }
      this.unit.damages.push(JSON.parse(JSON.stringify(this.damageToAdd)));
      this.damageToAdd = {};
      this.damageKey++;
      console.log("addDamage damages", this.unit.damages);
    },
    onChangeFE(newVal) {
      if (newVal == "E") this.unit.customsStatus = "D";
    },
    removeDamage(damage) {
      var index = this.unit.damages.indexOf(damage);
      console.log("removeDamage index damage", index, damage);
      if (index > -1) {
        this.unit.damages.splice(index, 1);
        this.damageKey++;
      }
    },
    damageTypeRender: (item) => item.code + " - " + item.description,
    async trainVoyageChanged() {
      if (!this.unit.handlingOutTrainVoyage) {
        this.listWagonLicensePlates = [];
        return;
      }
      this.listWagonLicensePlates = await this.$api.yardData.listWagonLicensePlates(this.unit.handlingOutTrainVoyage.id);
    },
    filterOrders(item, queryText, itemText) {
      return (
        (item.reference && item.reference !== null && item.reference.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) ||
        (item.extCode && item.extCode !== null && item.extCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      );
    },
    voyageCodeRender: (item) => item.code + " - " + (item.etd ? moment(item.etd).format("DD/MM/YYYY HH:mm") : "") + " - " + item.destination,
  },
};
</script>

<style></style>
