<template>
  <v-container fluid class="px-0 mx-0">
    <v-row align="center" v-if="cargoTypeInternal === null" class="mt-2">
      <v-col cols="6" class="text-right text-h3 text-uppercase font-weight-light"> {{ tLabel("Tipo consegna") }} </v-col>
      <v-col cols="6" class="text-left">
        <v-btn :outlined="cargoTypeInternal === null || cargoTypeInternal !== 'UNT'" x-large color="primary" @click="setCargoType('UNT')">
          <v-icon x-large class="pr-2">mdi-view-week-outline</v-icon>UNIT
        </v-btn>
        <v-btn :outlined="cargoTypeInternal === null || cargoTypeInternal !== 'GOO'" x-large color="primary" class="ml-2" @click="setCargoType('GOO')">
          <v-icon x-large class="pr-2">mdi-dolly</v-icon>MERCE
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" v-if="cargoTypeInternal === 'UNT'">
      <v-col cols="12">
        <UnitComposeForm :unit="cargoToCompose" ref="unitComposeForm" />
      </v-col>
    </v-row>
    <v-row align="center" v-if="cargoTypeInternal === 'GOO'">
      <v-col cols="12">
        <GoodsComposeForm :goods="cargoToCompose" ref="goodsComposeForm" />
      </v-col>
    </v-row>
    <v-row align="center" v-if="cargoTypeInternal !== null" class="mt-0">
      <v-col cols="12" class="pt-0">
        <v-card rounded="0" elevation="0">
          <v-card-actions class="blue lighten-5">
            <v-btn small v-if="cargoType === null && cargoTypeInternal !== null" color="primary" outlined @click="setCargoType(null)">
              <v-icon small class="pr-2">mdi-swap-horizontal</v-icon>{{ tLabel("Cambia tipo cargo") }}
            </v-btn>
            <v-spacer />
            <v-btn small v-if="editing === true" outlined @click="editCargoDiscard"><v-icon small class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
            <v-btn small v-if="editing === true" color="primary" @click="editCargoConfirm"> <v-icon small class="pr-2">mdi-check</v-icon>{{ tLabel("Conferma") }} </v-btn>
            <v-btn small v-else color="primary" @click="addCargo"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" v-if="cargoTypeInternal !== null && cargoes.length > 0" class="mx-0">
      <v-col cols="12" class="grey lighten-4 d-flex flex-row">
        <div v-for="cargo in cargoes" :key="cargo.id">
          <v-hover v-slot="{ hover }" open-delay="80">
            <v-btn
              :outlined="!isCargoSelected(cargo) && !hover"
              large
              rounded
              class="mr-2 px-2 text-subtitle-1"
              color="secondary"
              @click="prepareEditCargo(cargo)"
              :title="tLabel('Modifica')"
              :disabled="editing ? true : false"
            >
              {{ cargo.code }} {{ cargo.sysType === 'GOO' ? cargo.quantity : '' }}
              <v-icon size="30" class="ml-3" @click="removeCargo(cargo)" :title="tLabel('Elimina')">mdi-close-circle</v-icon>
            </v-btn>
          </v-hover>
        </div>
      </v-col>
    </v-row>
    <v-row align="center" v-else-if="cargoTypeInternal !== null && cargoes.length === 0" class="mx-0">
      <v-col cols="12" class="grey lighten-4 text-uppercase font-weight-light text-h5 text-left"> 
        {{tLabel("Nessun cargo aggiunto.")}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoodsComposeForm from "@/components/yard/GoodsComposeForm";
import UnitComposeForm from "@/components/yard/UnitComposeForm";
import YardMixin from "../../mixins/yard/YardMixin";
import ToastMixin from "../../mixins/ToastMixin";

export default {
  name: "CargoesComposePanel",
  data() {
    return {
      cargoToCompose: {},
      cargoTypeInternal: null,
      editing: false,
      cargoToEditIndex: -1,
    };
  },
  components: { GoodsComposeForm, UnitComposeForm },
  mixins: [YardMixin, ToastMixin],
  emits: ["editing"],
  props: {
    cargoes: {
      type: Array,
      required: true,
    },
    cargoType: {
      type: String,
      required: false,
      default: null,
    },
  },
  mounted() {
    if (!this.cargoType && ["UNT", "GOO"].includes(process.env.VUE_APP_DEFAULT_CARGO_TYPE)) {
      this.setCargoType(process.env.VUE_APP_DEFAULT_CARGO_TYPE);
    } else
      this.setCargoType(this.cargoType);
  },
  watch: {
    cargoType(type) {
      this.setCargoType(type);
    },
  },
  methods: {
    isCargoSelected(cargo) {
      return this.cargoToEditIndex === this.cargoes.indexOf(cargo);
    },
    setCargoType(type) {
      this.cargoTypeInternal = null;
      if (!!type) {
        this.cargoTypeInternal = type;
      } else {
        this.prepareCompose();
      }
    },
    prepareCompose() {
      this.cargoToCompose = {};
      if (this.cargoes.length > 0) {
        this.cargoes.splice(0, this.cargoes.length);
      }
      this.editing = false;
      this.$emit("editing", this.editing);
      this.cargoToEditIndex = -1;
    },
    addCargo() {
      let ref = this.cargoTypeInternal === "GOO" ? "goodsComposeForm" : "unitComposeForm";
      const valid = this.$refs[ref].validate();
      if (!valid) {
        return;
      }
      if (this.cargoTypeInternal === "UNT" && this.cargoToCompose.typeClass == "CTR" && !this.cargoToCompose.skipCheckDigitUtiId) {
        let verificato = this.verifycontainercode(this.cargoToCompose.code);
        console.log("addCargo verifycontainercode", this.cargoToCompose.code, verificato);
        if (!verificato) {
          this.showError("Codice Unit non conforme! Selezionare 'Forza UNIT' per inserire comunque il codice digitato.");
          return;
        }
      }
      this.cargoToCompose.sysType = this.cargoTypeInternal;
      this.cargoes.push(JSON.parse(JSON.stringify(this.cargoToCompose)));
      this.$refs[ref].initForm();
    },
    prepareEditCargo(cargo) {
      let ref = this.cargoTypeInternal === "GOO" ? "goodsComposeForm" : "unitComposeForm";
      this.editing = true;
      this.$emit("editing", this.editing);
      this.cargoToCompose = JSON.parse(JSON.stringify(cargo));
      this.cargoToEditIndex = this.cargoes.indexOf(cargo);
      if (this.cargoTypeInternal === "GOO") {
        this.$refs[ref].setGoodsType(cargo.goodsTypeCode);
      }
      if (this.cargoTypeInternal === "UNT") {
        this.$refs[ref].setUnitTypeSelected(cargo.typeCode);
      }
    },
    editCargoDiscard() {
      let ref = this.cargoTypeInternal === "GOO" ? "goodsComposeForm" : "unitComposeForm";
      this.$refs[ref].initForm();
      this.editing = false;
      this.$emit("editing", this.editing);
      this.cargoToEditIndex = -1;
    },
    editCargoConfirm() {
      let ref = this.cargoTypeInternal === "GOO" ? "goodsComposeForm" : "unitComposeForm";
      const valid = this.$refs[ref].validate();
      if (!valid) {
        return;
      }
      if (this.cargoTypeInternal === "UNT" && this.cargoToCompose.typeClass == "CTR" && !this.cargoToCompose.skipCheckDigitUtiId) {
        let verificato = this.verifycontainercode(this.cargoToCompose.code);
        if (!verificato) {
          this.showError("Codice Unit non conforme! Selezionare 'Forza UNIT' per inserire comunque il codice digitato.");
          return;
        }
      }
      if (this.cargoToEditIndex > -1) {
        this.cargoes.splice(this.cargoToEditIndex, 1, JSON.parse(JSON.stringify(this.cargoToCompose)));
      }
      this.$refs[ref].initForm();
      this.editing = false;
      this.$emit("editing", this.editing);
      this.cargoToEditIndex = -1;
    },
    removeCargo(cargo) {
      var index = this.cargoes.indexOf(cargo);
      if (index > -1) {
        this.cargoes.splice(index, 1);
      }
    },
  },
};
</script>

<style></style>
